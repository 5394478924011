.sceneViewer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #11151c;
}

.sceneViewer > .mainPanel {
  pointer-events: inherit;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.sceneViewer > .mainPanel > .mainViewer {
  width: 100%;
  height: 100%;
  border-color: #212d40;
}

.sceneViewer > .sidePanel {
  position: absolute;
  width: 300px;
  border-radius: 15px;
  z-index: 1000;
  max-height: 86vh;
  overflow: hidden;
}

.panel {
  background-color: rgba(18, 17, 23, 0.6);
  box-shadow: 0px 2px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);
  padding: 5px;
  border-radius: 15px;
}

.sceneViewer > .sidePanel::-webkit-scrollbar {
  display: none;
}

.sceneViewer .sidePanel .cameraViewer {
  position: relative;
  width: 240px;
  height: 240px;
  box-sizing: border-box;
  border-radius: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: #525252;
  overflow: hidden;
  background-color: #202020;
}

.sceneViewer > .sidePanel > .propertyPanel {
  width: 100%;
  box-sizing: border-box;
}
.multiSelectAlign {
  width: 330px !important;
}
.sceneViewer > .rightSidePanel {
  right: 15px;
  top: 70px;
}

.sceneViewer > .leftSidePanel {
  left: 15px;
  top: 70px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 250px !important;
}

.canvas {
  position: absolute !important;
  width: 100vw;
  height: 100vh;
  pointer-events: inherit;
  border-radius: 15px;
  /* background-color: red; */
}

.saveImageButton {
  position: absolute;
  top: 20px;
  left: 120px;
  background: #181c20;
  color: #8c92a4;
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  font-family: var(--leva-fonts-mono);
}

.alignButton {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: #11151c;
  color: white;
  border-radius: 15px;
  padding: 10px;
}
