.label {
  color: rgb(209, 209, 209);
  font-size: 12px;
  margin: 5px 16px 4px 5px;
  font-weight: 200;
  user-select: none;
}

.label_scale {
  color: rgb(209, 209, 209);
  font-size: 12px;
  margin: 5px 0px 4px 5px;
  font-weight: 200;
  width: fit-content;
}

.propHeading {
  width: 100%;
  height: 20px;
  color: white;
  font-size: 14px;
  margin: 10px 5px 10px 5px;
  font-weight: 500;
}

.prop-btn {
  height: 25px !important;
  width: 25px !important;
  color: white;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
}

.prop-btn-active {
  background: rgba(204, 197, 197, 0.205) !important;
}

.prop-btn:hover {
  background-color: rgba(204, 197, 197, 0.205) !important;
}
