.propField {
  width: 20%;
  height: 20px;
  margin: 10px 2.5px 10px 2.5px;
  user-select: none;
}

.propField > .propField-input:focus {
  width: 22%;
  height: 20px;
  background-color: black;
  color: rgb(241, 241, 241);
  font-size: 12px;
  appearance: none;
  text-align: center;
  border-radius: 5px;
  font-weight: 200;
}

.propField > .propField-input {
  width: 22%;
  min-width: 22%;
  padding: 0 5px;
  height: 20px;
  display: inline-block;
  background-color: rgb(40, 40, 40);
  color: white;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: 400;
}
